<template>
  <define-reset-ring-central-template>
    <div
      v-tooltip="$t('COMMUNICATION.TOOL.RESET_RING_CENTRAL')"
      data-cy="reset-ringcentral"
      class="cursor-pointer transition-all transform duration-300 hover:-rotate-180 w-[24px] h-[24px]"
      @click="resetRingCentral"
    >
      <icon-base icon="reload" height="24" width="24" />
    </div>
  </define-reset-ring-central-template>
  <div
    class="border border-divider rounded shadow-main p-2-5 space-y-2 w-[185px]"
    :class="
      communicationToolOptions.showAsPopover
        ? 'bg-gray-100 hover:bg-gray-200'
        : 'bg-white hover:bg-gray-50'
    "
  >
    <div
      v-if="communicationToolOptions.showAsPopover"
      class="text-xs flex flex-col space-y-1"
    >
      <p>{{ $t("COMMUNICATION.TOOL.POPOVER_NOTICE") }}</p>
      <div
        class="text-primary cursor-pointer"
        @click="communicationToolOptions.showAsPopover = false"
      >
        {{ $t("COMMUNICATION.TOOL.RESTORE_TOOL") }}
      </div>
    </div>
    <template v-else>
      <div
        data-cy="communication-tool-static"
        class="flex justify-between items-center"
      >
        <div class="text-headline">{{ $t("COMMON.COMMUNICATION") }}</div>
        <div
          v-tooltip="$t('COMMON.SHOW_AS_POPOVER')"
          data-cy="init-popover"
          class="px-1 cursor-pointer"
          @click="initPopover"
        >
          <icon-base :icon="IconPopup" />
        </div>
      </div>
      <div
        class="pt-2 border-t border-divider grid grid-cols-4 gap-2 items-center group"
        data-cy="call-row"
      >
        <tooltip interactive>
          <template #default>
            <div
              class="rounded-full w-[26px] h-[26px]"
              :class="
                contactName
                  ? 'cursor-pointer bg-green-50 hover:bg-green-100 text-green-500'
                  : 'bg-gray-100 text-gray-400'
              "
              @click="handleCallClient"
            >
              <div class="flex items-center justify-center h-full">
                <icon-base
                  data-cy="telephoneIcon"
                  :icon="IconTelephone"
                  :icon-color="contactName ? 'currentColor' : '#C2C6CD'"
                />
              </div>
            </div>
          </template>
          <template #content>
            {{ telephoneNumber }}
          </template>
        </tooltip>

        <div class="col-span-2 items-center flex space-x-2">
          <span>
            {{ $t("COMMON.CALL") }}
          </span>
          <span class="hidden group-hover:flex">
            <icon-base
              v-tooltip="`${$t('COMMON.NEW')} ${$t('DEALS.NOTES.CALL_NOTE')}`"
              data-cy="call-note"
              :icon="IconNotes"
              class="text-green-500 cursor-pointer"
              @click="
                handleShowCommunicationModal($event, CommunicationType.call)
              "
            />
          </span>
        </div>
        <reset-ring-central-template />
      </div>
      <template v-if="showCommunicationItems">
        <div
          class="grid grid-cols-4 gap-2 items-center cursor-pointer group"
          @click="handleShowCommunicationModal($event, CommunicationType.sms)"
        >
          <div
            data-cy="sms-link"
            class="rounded-full w-[26px] h-[26px] flex items-center justify-center bg-turquoise bg-opacity-10 email-link-icon group-hover:bg-opacity-20"
          >
            <icon-base :icon="IconMessage" class="text-secondary-blue" />
          </div>
          <div class="col-span-2">
            {{ $t("COMMON.SEND") }} {{ $t("COMMON.SMS") }}
          </div>
        </div>
        <div
          class="grid grid-cols-4 gap-2 items-center cursor-pointer group"
          @click="handleShowCommunicationModal($event, CommunicationType.email)"
        >
          <div
            data-cy="email-link"
            class="rounded-full w-[26px] h-[26px] flex items-center justify-center bg-turquoise bg-opacity-10 email-link-icon group-hover:bg-opacity-20"
          >
            <icon-base :icon="IconEmail" class="text-turquoise" />
          </div>
          <div class="col-span-2">
            {{ $t("COMMON.SEND") }} {{ $t("COMMON.EMAIL") }}
          </div>
        </div>
        <div
          class="grid grid-cols-4 gap-2 items-center cursor-pointer group"
          @click="handleShowCommunicationModal($event, CommunicationType.task)"
        >
          <div
            data-cy="task-link"
            class="rounded-full w-[26px] h-[26px] flex items-center justify-center bg-flush-orange bg-opacity-10 task-link-icon group-hover:bg-opacity-20"
          >
            <icon-base :icon="IconNotes" class="text-flush-orange" />
          </div>
          <div class="col-span-2">
            {{ $t("COMMON.NEW") }} {{ $t("COMMON.TASK") }}
          </div>
        </div>
        <div
          v-if="hasActiveTemplateCustomWebhooks"
          class="relative grid grid-cols-4 gap-2 items-center cursor-pointer group"
          @click="showManualWebhooks = !showManualWebhooks"
        >
          <div
            data-cy="webhook-button"
            class="rounded-full w-[26px] h-[26px] flex items-center justify-center bg-secondary-yellow bg-opacity-10 task-link-icon group-hover:bg-opacity-20"
          >
            <icon-base :icon="IconWebhook" class="text-secondary-yellow" />
          </div>
          <div class="col-span-2 text-nowrap">
            {{ $t("ORCHESTRATION.TRIGGER") }} {{ $t("COMMON.WEBHOOK") }}
          </div>
          <custom-webhooks-list
            v-if="showManualWebhooks"
            v-on-click-outside="() => (showManualWebhooks = false)"
            :app-id="activeDeal.id"
            :items="activeTemplateCustomWebhooks"
            :style="{
              left: '175px',
              bottom: '0px'
            }"
          />
        </div>
      </template>
      <div>
        <lf-pill
          data-cy="toggle-modal"
          size="sm"
          class="uppercase font-bold cursor-pointer"
          color-index="15"
          @click="toggleModal"
        >
          <div class="space-x-2 flex items-center">
            <div data-cy="contact-name" class="w-30 truncate text-left">
              <span v-if="contactName">{{ contactName }}</span>
              <span v-else>
                {{ $t("ACTIVITY.CONTACTS.NO_CONTACT") }}
              </span>
            </div>
            <icon-chevron-up
              class="transition-all transform cursor-default"
              :class="{ 'rotate-180': !showModal }"
              fill="#9ca3af"
            />
          </div>
        </lf-pill>
        <contacts-modal
          v-if="showModal && activeDeal"
          class="z-[51]"
          :value="activeDeal as ApplicationWithContactData"
          position="top"
        />
      </div>
    </template>
  </div>
  <teleport v-if="communicationToolOptions.showAsPopover" to="#modals">
    <vue-resizable
      data-cy="communication-tool-draggable"
      class="z-[101]"
      drag-selector=".draggable"
      :width="draggableToolWidth"
      :height="50"
      :top="communicationToolOptions.y"
      :left="communicationToolOptions.x"
      :active="[]"
      @drag:end="updatePosition"
    >
      <div
        class="px-2 py-1 rounded-full shadow-md border border-disabled flex items-center space-x-4 bg-dark text-white draggable"
      >
        <icon-base
          :icon="IconCardView"
          width="16"
          height="16"
          view-box="0 0 24 24"
          class="transform rotate-90 cursor-grab"
        />
        <div class="flex items-center space-x-2">
          <template v-if="showCommunicationItems">
            <div
              v-tooltip="$t('COMBINATION.SEND_EMAIL')"
              class="modal-icon"
              @click="
                handleShowCommunicationModal($event, CommunicationType.email)
              "
            >
              <div class="flex items-center justify-center h-full">
                <icon-base :icon="IconEmail" />
              </div>
            </div>
            <div
              v-tooltip="$t('COMBINATION.SEND_SMS')"
              class="modal-icon"
              @click="
                handleShowCommunicationModal($event, CommunicationType.sms)
              "
            >
              <div class="flex items-center justify-center h-full">
                <icon-base :icon="IconMessage" />
              </div>
            </div>
            <div
              v-tooltip="`${$t('COMMON.NEW')} ${$t('COMMON.TASK')}`"
              data-cy="task-link"
              class="modal-icon"
              @click="
                handleShowCommunicationModal($event, CommunicationType.task)
              "
            >
              <div class="flex items-center justify-center h-full">
                <icon-base :icon="IconNotes" />
              </div>
            </div>
          </template>
          <tooltip interactive :append-to="body">
            <template #default>
              <div class="modal-icon" @click="handleCallClient">
                <div class="flex items-center justify-center h-full">
                  <icon-base :icon="IconTelephone" />
                </div>
              </div>
            </template>
            <template #content>
              {{ telephoneNumber }}
            </template>
          </tooltip>
          <div
            v-tooltip="`${$t('COMMON.NEW')} ${$t('DEALS.NOTES.CALL_NOTE')}`"
            data-cy="call-note"
            class="modal-icon"
            @click="
              handleShowCommunicationModal($event, CommunicationType.call)
            "
          >
            <div class="flex items-center justify-center h-full relative">
              <icon-base
                :icon="IconTelephone"
                class="absolute top-[13px] left-[7px]"
              />
              <icon-base
                :icon="IconNotes"
                class="absolute top-[10px] right-[6px]"
                width="12"
                height="12"
                view-box="0 0 16 16"
              />
            </div>
          </div>
          <div
            v-if="hasActiveTemplateCustomWebhooks"
            v-tooltip="`${$t('ORCHESTRATION.TRIGGER')} ${$t('COMMON.WEBHOOK')}`"
            class="modal-icon relative cursor-pointer flex items-center justify-center"
            data-cy="webhook-button"
            @click="showManualWebhooks = !showManualWebhooks"
          >
            <icon-base :icon="IconWebhook" />
            <custom-webhooks-list
              v-if="showManualWebhooks"
              ref="webhooksList"
              :app-id="activeDeal.id"
              :items="activeTemplateCustomWebhooks"
              :style="webhooksDraggableBlockStyle"
            />
          </div>
        </div>
        <div class="flex items-center space-x-1">
          <div class="text-xxs uppercase font-semibold text-disabled">
            {{ $t("COMMON.CONTACT") }}:
          </div>
          <tooltip
            ref="contactsTooltip"
            placement="bottom-start"
            trigger="click"
            hide-on-click="toggle"
            :arrow="false"
            sticky="reference"
            max-width="450"
            theme="none"
            interactive
            :popper-options="popperOptions"
          >
            <template #default>
              <lf-pill
                data-cy="toggle-modal"
                class="uppercase font-bold cursor-pointer"
                color-index="15"
                opaque
              >
                <div class="space-x-2 flex items-center">
                  <div data-cy="contact-name" class="w-30 truncate text-left">
                    <span v-if="contactName">{{ contactName }}</span>
                    <span v-else>
                      {{ $t("ACTIVITY.CONTACTS.NO_CONTACT") }}
                    </span>
                  </div>
                  <icon-chevron-up
                    class="transition-all transform cursor-default"
                    :class="{ 'rotate-180': !contactsTooltip?.state.isVisible }"
                    fill="#ffffff"
                  />
                </div>
              </lf-pill>
            </template>
            <template #content>
              <contacts-modal
                v-if="activeDeal"
                data-cy="contacts-modal-draggable"
                :value="activeDeal as ApplicationWithContactData"
                ignore-placement
              />
            </template>
          </tooltip>
        </div>
        <reset-ring-central-template />
        <div
          v-tooltip="$t('COMMUNICATION.TOOL.CLOSE_POPOVER')"
          data-cy="close-popover"
          class="px-1 cursor-pointer"
          @click="closePopoverAndClearPosition"
        >
          <icon-base :icon="IconPopup" class="transform rotate-180" />
        </div>
      </div>
    </vue-resizable>
  </teleport>
  <teleport v-if="callDialog" to="#modals">
    <communication-log-call-dialog
      class="fixed z-50 shadow-lg"
      :value="callDialog"
      :initial-values="initialCallDialogValue"
      @close-dialog="callDialog = null"
    />
  </teleport>
  <send-deal-communication-modal
    v-if="showCommunicationModal"
    :type="modalType"
    :deal-id="activeDeal.id"
    :deal-name="activeDeal.business.business_legal_name"
    :initial-values="{ x: cursorPosition.x, y: cursorPosition.y }"
    anchor-bottom-left
    @close-dialog="showCommunicationModal = false"
  />
</template>

<script setup lang="ts">
import { computed, ref, type Ref } from "vue";
import { useContacts } from "@/hooks/contacts";
import { useDeals } from "@/hooks/deals";
import { useLocalStorageSetting } from "@/hooks/options";
import VueResizable from "vue-resizable";
import ContactsModal from "@/views/deals/components/contacts/ContactsModal.vue";
import CommunicationLogCallDialog from "@/components/communicationLogs/communication/CommunicationLogCallDialog.vue";
import SendDealCommunicationModal from "@/components/communication/SendDealCommunicationModal.vue";
import IconCardView from "@/components/icons/IconCardView.vue";
import CustomWebhooksList from "@/components/communication/CustomWebhooksList.vue";
import type { ApplicationWithContactData } from "@/models/applications";
import type { TippyComponent } from "vue-tippy";
import type {
  CallLog,
  CommunicationModalType
} from "@/models/communicationLogs";
import { CommunicationType } from "@/enums/communicationLogs";

import { usePromiseWrapper } from "@/hooks/common";
import usersApiService from "@/services/modules/users";
import type { IUser } from "@/models/users";
import type { ResizableEventValues } from "@/models/common";
import { useStore } from "vuex";
import { createReusableTemplate } from "@vueuse/core";
import IconBase from "@/components/ui/IconBase.vue";
import IconPopup from "@/components/icons/IconPopup.vue";
import IconEmail from "@/components/icons/IconEmail.vue";
import IconMessage from "@/components/icons/IconMessage.vue";
import IconNotes from "@/components/icons/IconNotes.vue";
import IconTelephone from "@/components/icons/IconTelephone.vue";
import { useAuth } from "@/hooks/auth";
import IconWebhook from "@/components/icons/IconWebhook.vue";
import { useActiveWorkflowTemplate } from "@/hooks/workflow";

const { activeDeal } = useDeals();
const { isLendflowUser } = useAuth();

const [DefineResetRingCentralTemplate, ResetRingCentralTemplate] =
  createReusableTemplate();

const { telephoneNumber, contactName, callClient } = useContacts(
  activeDeal as unknown as Ref<ApplicationWithContactData>
);

const { activeTemplateCustomWebhooks } = useActiveWorkflowTemplate();

const webhooksList = ref<InstanceType<typeof CustomWebhooksList> | null>(null);

const { getters } = useStore();
const communicationToolOptions = useLocalStorageSetting(
  "communicationToolOptions",
  {
    x: 0,
    y: 0,
    showAsPopover: false
  }
);

const callDialog = ref<CallLog | null>(null);
const showModal = ref(false);
const body = ref(document.body);
const showCommunicationModal = ref(false);
const modalType = ref<CommunicationModalType>(CommunicationType.email);
const cursorPosition = ref({ x: 0, y: 0 });
const contactsTooltip = ref<TippyComponent | null>(null);
const popperOptions = ref<TippyComponent["popperOptions"]>({
  modifiers: [
    {
      name: "flip",
      options: { fallbackPlacements: ["bottom", "top"] }
    }
  ]
});
const showManualWebhooks = ref(false);

const { loading: isResettingRingCentral, fetchWrapper: resetRingCentral } =
  usePromiseWrapper(async () => {
    if (!currentUser.value?.id) {
      return;
    }

    await usersApiService.resetRingCentral(currentUser.value.id);
  });

const initialCallDialogValue = computed(() => {
  const callDialogWidth = 570;
  const callDialogHeight = 180;
  return {
    x: window.innerWidth / 2 - callDialogWidth / 2,
    y: window.innerHeight / 2 - callDialogHeight / 2
  };
});

const hasActiveTemplateCustomWebhooks = computed(
  () => !!Object.keys(activeTemplateCustomWebhooks.value).length
);

const draggableToolWidth = computed(() => {
  if (!showCommunicationItems.value) {
    return 405;
  }

  return hasActiveTemplateCustomWebhooks.value ? 646 : 606;
});

const webhooksDraggableBlockStyle = computed(() => {
  if (!webhooksList.value) {
    return {};
  }
  const webhooksListHeight = webhooksList.value.$el.clientHeight;
  if (communicationToolOptions.value.y <= webhooksListHeight) {
    return {
      left: "0px",
      top: "45px"
    };
  }
  return {
    left: "0px",
    bottom: "45px"
  };
});

const currentUser = computed<IUser>(() => getters["auth/user"]);
const showCommunicationItems = computed(
  () => activeDeal.value.workflow_name && isLendflowUser
);

const handleCallClient = async (e: MouseEvent) => {
  if (isResettingRingCentral.value || !telephoneNumber.value) {
    return;
  }
  const result = await callClient(e);
  if (result?.connectionLog?.id) {
    communicationToolOptions.value.x = result.event.x;
    communicationToolOptions.value.y = result.event.y;
    callDialog.value = result.connectionLog;
  }
};

const toggleModal = () => {
  showModal.value = !showModal.value;
};

const initPopover = (event: MouseEvent) => {
  communicationToolOptions.value = {
    x: communicationToolOptions.value?.x || event.x,
    y: communicationToolOptions.value?.y || event.y,
    showAsPopover: true
  };
};

const updatePosition = (position: ResizableEventValues) => {
  communicationToolOptions.value = {
    ...communicationToolOptions.value,
    x: position.left,
    y: position.top
  };
};

const closePopoverAndClearPosition = () => {
  communicationToolOptions.value = { x: 0, y: 0, showAsPopover: false };
};

const handleShowCommunicationModal = (
  e: MouseEvent,
  type: CommunicationModalType
) => {
  cursorPosition.value.x = e.x;
  cursorPosition.value.y = e.y;
  showCommunicationModal.value = true;
  modalType.value = type;
};
</script>

<style scoped>
.modal-icon {
  @apply rounded-full cursor-pointer bg-divider/20 hover:bg-divider/80 text-white hover:text-gray-600 transition-colors duration-300 w-[40px] h-[40px];
}
</style>
